import React, { useState, useRef } from 'react';

// MomentJS
import moment from 'moment';

// react-bootstrap imports
import Button from 'react-bootstrap/Button';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import Card from 'react-bootstrap/Card';
import Form from 'react-bootstrap/Form';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Tooltip from 'react-bootstrap/Tooltip';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';

function OverlaySelector(props) {

  const [selectedLayer, setSelectedLayer] = useState(0);

  const overlaySelector = useRef({current: {clientWidth: 0}});

  return (
    <>
      <Container style={{padding: '15px', width: "70%", zIndex: 999, position: 'relative'}}>
        <Row>
          <Col
            ref={overlaySelector}

          >
            <Card border='light'>
              <Row className="mx-0 flex-nowrap">

                <Col md="12" className="px-0">
                  <Card.Body style={{ padding: '0.75rem' }}>
                    <Form>
                      <Form.Group as={Row} controlId="formPlaintextEmail">
                        <Form.Label column sm="3" md="4">
                          Select Data Layer:
                        </Form.Label>
                        <Col sm="7" md="6">
                          <Form.Control
                            as="select"
                            onChange={props.updateProduct}
                            custom
                          >
                            {props.products.map(product => {
                              return (
                                <option key={product} value={product}>
                                  {product}
                                </option>
                              );
                            })}
                          </Form.Control>
                        </Col>
                        <Col sm="2" md="2" className="text-center d-flex flex-column">
                          <OverlayTrigger placement="top" overlay={<Tooltip>{props.download ? 'Download GeoTIFF' : 'Draw Polygon'}</Tooltip>}>
                            <Button
                              variant="light"
                              onClick={
                                () => {props.download ? props.openDownloadModal() : props.drawCoverage();}
                              }
                            >
                              <i className={props.download ? 'fas fa-download' : 'fas fa-draw-polygon'}></i>
                            </Button>
                          </OverlayTrigger>
                        </Col>
                      </Form.Group>
                      <Form.Row>
                        <Col xl="6" lg="6" md="6" sm="8" xs="12">
                          <OverlayTrigger placement="left" overlay={<Tooltip>Select DateTime</Tooltip>}>
                          <input
                            type="range"
                            className="slider rtl"
                            value={selectedLayer}
                            onChange={
                              e => {
                                setSelectedLayer(e.target.value);
                                props.updateOverlay(props.layers[e.target.value].name);
                              }
                            }
                            min={0}
                            max={props.layers.length - 1}
                          />
                        </OverlayTrigger>
                        </Col>
                        <Col xl="3" lg="3" md="3" sm="2" xs="6" className="text-center d-flex flex-column">
                          <ButtonGroup size="sm">
                            <Button
                              variant="light"
                              onClick={
                                () => {
                                  let newLayer = selectedLayer + 10;
                                  newLayer = newLayer >= props.layers.length ? props.layers.length - 1 : newLayer;
                                  setSelectedLayer(newLayer);
                                  props.updateOverlay(props.layers[newLayer].name);
                                }
                              }
                            >
                              <i className="fas fa-angle-double-left"></i>
                            </Button>
                            <Button
                              variant="light"
                              onClick={
                                () => {
                                  let newLayer = selectedLayer + 1;
                                  newLayer = newLayer >= props.layers.length ? props.layers.length - 1 : newLayer;
                                  setSelectedLayer(newLayer);
                                  props.updateOverlay(props.layers[newLayer].name);
                                }
                              }
                            >
                              <i className="fas fa-angle-left"></i>
                            </Button>
                          </ButtonGroup>
                        </Col>
                        <Col xl="3" lg="3" md="3" sm="2" xs="6" className="text-center d-flex flex-column">
                          <ButtonGroup size="sm">
                            <Button
                              variant="light"
                              onClick={
                                () => {
                                  let newLayer = selectedLayer - 1;
                                  newLayer = newLayer < 0 ? 0 : newLayer;
                                  setSelectedLayer(newLayer);
                                  props.updateOverlay(props.layers[newLayer].name);
                                }
                              }
                            >
                              <i className="fas fa-angle-right"></i>
                            </Button>
                            <Button
                              variant="light"
                              onClick={
                                () => {
                                  let newLayer = selectedLayer - 10;
                                  newLayer = newLayer < 0 ? 0 : newLayer;
                                  setSelectedLayer(newLayer);
                                  props.updateOverlay(props.layers[newLayer].name);
                                }
                              }
                            >
                              <i className="fas fa-angle-double-right"></i>
                            </Button>
                          </ButtonGroup>
                        </Col>
                      </Form.Row>
                      <Form.Row>
                        <Col className="text-center">
                          { props.products.length === 0 ? <> Loading... </> :
                            moment.utc(
                              props.layers[selectedLayer].name.split('_')[4].slice(1),
                              'YYYYDDDhhmmss'
                            ).local().format('lll')
                          }
                        </Col>
                      </Form.Row>
                    </Form>
                  </Card.Body>
                </Col>
              </Row>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default OverlaySelector;
