import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import classnames from 'classnames';
import Button from '@material-ui/core/Button';

import buriedIceBirdsEye from '../Media/BuriedIce/BirdsEye.PNG'
import buriedIceEmbeddedVideo from '../Media/BuriedIce/EmbeddedVideo.PNG'
import buriedIceMovementInCesium from '../Media/BuriedIce/MovementInCesium.PNG'
import YelpProject_Diagram from '../Media/YelpProject_Diagram.PNG'
import YelpProject_Sentiments from '../Media/YelpProject_Sentiments.PNG'
import RainOrShineMain from '../Media/RainOrShineMain.jpg'
import CS505_poster from '../Media/CS505_poster.png'
import CS591_poster from '../Media/CS591_poster.png'

import CustomPicture from './CustomPicture'


const buriedIceSteps = [{
    label: 'Birds Eye View of the McMurdo Dry Valleys',
    imgPath: buriedIceBirdsEye,
  }, {
    label: 'Embedded Video Inside the KRPano Component',
    imgPath: buriedIceEmbeddedVideo,
  }, {
    label: 'The right screen showing Cesium could be moved independently of the panorama',
    imgPath: buriedIceMovementInCesium,
}];

const yelpSteps = [{
    label: 'Subtopics Extracted from a Sample Review',
    imgPath: YelpProject_Diagram,
  }, {
    label: 'Using Sentiment and Average Star Rating to Generate Suggestions',
    imgPath: YelpProject_Sentiments,
}];

const nycSteps = [{
    label: 'Project Poster',
    imgPath: CS591_poster,
}];
const electionSteps = [{
    label: 'Project Poster',
    imgPath: CS505_poster,
}];
const rainOrShineSteps = [{
    label: 'Landing Page for the RainOrShine App',
    imgPath: RainOrShineMain,
}];

class Projects extends React.Component {

    render() {
        return (
            <div className='home'>
                <h2>Buried Ice</h2>
                <p>Worked with Cesium 3D Globe Viewer Implementation in JavaScript and
                KRPano panorama image viewer in order to create a “Virtual Tour of
                Antarctica”. Implemented Cesium and synchronized it with the KRPano
                viewer. Created Tiles for imagery and terrain in cesium. The user is
                able to navigate the McMurdo Dry Valleys in a first person view while
                also being able to see an aerial view of their location.</p>

                <CustomPicture pictureArray={buriedIceSteps}/>

                <h2>Generate Suggestions from Yelp Reviews</h2>
                <p>Generated tips for Yelp Restaurants. Used LDA model to extract subtopics from
                    Yelp Reviews, then rate the subtopics using Star Rating and Sentiment Trees.</p>

                <CustomPicture pictureArray={yelpSteps}/>

                <h2>Investigating NYC Transit</h2>
                <p></p>

                <CustomPicture pictureArray={nycSteps}/>

                <h2>Clustering Tweets of the 2016 Election</h2>
                <p></p>

                <CustomPicture pictureArray={electionSteps}/>

                <h2>RainOrShine</h2>
                <p>Created an online weather app using JavaScript and MongoDB that will notify the
                    user when inclement weather is near. This used the Google Maps API, Twilio API,
                    and Dark Sky Forecast API.</p>

                <CustomPicture pictureArray={rainOrShineSteps}/>



            </div>
        );
    }
}

const styles = theme => ({
  root: {
    maxWidth: 400,
    flexGrow: 1,
  },
  header: {
    display: 'flex',
    alignItems: 'center',
    height: 50,
    paddingLeft: theme.spacing.unit * 4,
    backgroundColor: theme.palette.background.default,
  },
  img: {
    display: 'block',
    overflow: 'hidden',
    width: '100%',
  },
});

Projects.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
};

export default withStyles(styles, { withTheme: true })(Projects);
