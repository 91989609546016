import React from "react";
import { SignOut } from "aws-amplify-react";

import classNames from 'classnames';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';

const styles = theme => ({
    button: {
        margin: theme.spacing.unit,
    }, root: {
        display: "flex",
        flexWrap: "wrap"
    }, margin: {
        margin: theme.spacing.unit
    } ,withoutLabel: {
        marginTop: theme.spacing.unit * 3
    }, textField: {
        flexBasis: 200
    }
});

class CustomSignOut extends SignOut {
    render() {
        const { classes } = this.props;

        return (
            <div>
                <Button
                    className={classNames(classes.margin)}
                    style={{float:'right', font:"12px", zIndex: 10}}
                    variant="contained"
                    color='default'
                    onClick={() => super.signOut()}
                >  Logout </Button>
            </div>
        );
    }
}

export default withStyles(styles)(CustomSignOut);
