import React from "react";
import { SignIn } from "aws-amplify-react";

import classNames from 'classnames';
import { withStyles } from '@material-ui/core/styles';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import Button from '@material-ui/core/Button';

const styles = theme => ({
    button: {
        margin: theme.spacing.unit,
    }, root: {
        display: "flex",
        flexWrap: "wrap"
    }, margin: {
        margin: theme.spacing.unit
    } ,withoutLabel: {
        marginTop: theme.spacing.unit * 3
    }, textField: {
        flexBasis: 200
    }
});

class CustomSignIn extends SignIn {
    constructor(props) {
        super(props);
        this._validAuthStates = ["signIn", "signedOut", "signedUp"];

        this.handleSubmit = this.handleSubmit.bind(this);

    }

    handleSubmit(event) {
        event.preventDefault();
        super.inputs = this.inputs
        super.signIn()
    }

    showComponent(theme) {
        const { classes } = this.props;

        return (
          <div>
            <form
                className={classNames(classes.margin, classes.textField)}
                onSubmit={this.handleSubmit}
                style={{position: 'relative', zIndex:10, float:'right', font:"12px"}}>

                <FormControl className={classNames(classes.margin, classes.textField)}>
                    <InputLabel >Username</InputLabel>
                    <Input
                        id="username"
                        key="username"
                        name="username"
                        type="text"
                        onChange={this.handleInputChange}/>
                </FormControl>

                <FormControl className={classNames(classes.margin, classes.textField)}>
                    <InputLabel >Password</InputLabel>
                    <Input
                        id="password"
                        key="password"
                        name="password"
                        type="password"
                        onChange={this.handleInputChange}/>
                </FormControl>

                <Button className={classNames(classes.margin, classes.textField)}
                        type="submit"
                        variant="contained"
                        color='default'> Login </Button>
            </form>
          </div>
        );
    }
}

export default withStyles(styles)(CustomSignIn);
