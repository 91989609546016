import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom'

import 'bootstrap/dist/css/bootstrap.min.css';
import '@fortawesome/fontawesome-free/css/all.css';

import { ThemeProvider as MuiThemeProvider } from '@material-ui/core/styles';

import AppWithAuth from './Components/AppWithAuth';
import theme from './Styling/theme';

import './Styling/index.css';
import 'typeface-roboto';

class MyPage extends React.Component {
    render() {
        return (
              <MuiThemeProvider theme={theme}>
                  <BrowserRouter>
                    <AppWithAuth />
                  </BrowserRouter>
              </MuiThemeProvider>
        )
    }
}

ReactDOM.render(<MyPage/>, document.getElementById("root"));
