import { createTheme } from '@material-ui/core/styles';

// import grey from '@material-ui/core/colors/grey';
import lightBlue from '@material-ui/core/colors/lightBlue';

const theme = createTheme({
    palette: {
        primary: {
            light: lightBlue['A100'],
            main: lightBlue['500'],
            dark: lightBlue['A700'],
            contrastText: '#000'
        },
        secondary: {
            light: '#bdbdbd',
            main: '#efefef',
            dark: '#8d8d8d',
            contrastText: '#000'
        }
    },

    // overrides: {
    //     MuiButton: {
    //         root: {
    //             color: 'black',
    //             '&:hover': {
    //                 backgroundColor: 'lightBlue'
    //             },
    //             '&:focus': {
    //                 backgroundColor: 'red'
    //             }
    //         }
    //     }
    // }
});

export default theme;
