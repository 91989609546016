import React from 'react'

import Header from './Header'
import Main from './Main'
import CustomSignOut from "./CustomAuth/CustomSignOut";


class App extends React.Component{
    componentDidUpdate() {
        console.log(this.props.authState )
    }

    render() {
        if (this.props.authState === "signedIn") {
            return (
                <div style={{height: '100vh'}}>
                    <CustomSignOut />
                    <Header/>
                    <Main/>
                </div>
            );
        } else if (this.props.authState === 'forgotPassword'){
            return (
                <div style={{height: '100vh'}}></div>
            );
        } else {
            return (
                <div style={{height: '100vh'}}>
                    <Header/>
                    <Main/>
                </div>
            );
        }
    }
}


export default App;
