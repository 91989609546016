import React from 'react'
import {Link} from 'react-router-dom'
import Button from '@material-ui/core/Button';

import resume from '../Media/resume/JarrodLewisResume.pdf'
import jarrod from '../Media/Jarrod.JPG'
const Home = () => (
    <div className='home'>
        <h2> Get to know me! </h2>

        <img className='img-circular' src={jarrod}></img>
        <p>
            I'm a Software Engineer, Team Lead and graduate of Boston University. I pride myself in
            my ability to adapt in order to overcome any obstacle thrown my way.  I have experience
            working with a variety of languages and technologies including Python, JavaScript, Java,
            Docker, AWS, and Kubernetes. And I'm always prepared expand my skillset in order to adress
            the current issue.

        </p>
        <p>
            At Boston University I majored in Computer Science and minored in Earth Science and
            Environmental Science and am proud to be a first-generation college graduate. At AER, I
             work an the intersection of geospatial science
            and engineering. I'm passionate about expanding open science and delivering applications
            that provide real world insights. I enjoy tinkering and
            finding novel solutions to problems and have found my work in Software Engineering to be
             very rewarding.
        </p>
        <p>
            Outside of work I like to maintain a very active lifestyle whether that be walking and hiking with
            my wife, commuting to work on my bike or in the gym where I'm a recreational powerlifter.
        </p>

        <h2> Check out my </h2>
        <div>
            <Button
                component={Link}
                to='/projects'
                style={{color: 'black'}}
            > - Projects </Button>
        </div>
        <div>
            <Button
                href={resume}
                style={{color: 'black'}}
            > - Resume </Button>
        </div>
        <div>
            <Button
                href='https://github.com/LewisJarrod'
                style={{color: 'black'}}
            > - GitHub </Button>
        </div>
        <div>
            <Button
                href='https://www.linkedin.com/in/jarrod-lewis-542736125'
                style={{color: 'black'}}
            > - LinkedIn </Button>
        </div>

    </div>
)

export default Home
