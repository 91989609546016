import React from "react";
import Amplify from 'aws-amplify';

// import { Authenticator } from "aws-amplify-react/dist/Auth";
// import { SignIn, SignUp, SignOut, Greetings } from "aws-amplify-react";

import CustomSignIn from "./CustomAuth/CustomSignIn";
import App from "./App";
import theme from '../Styling/theme';


Amplify.configure({
    Auth: {
        // REQUIRED - Amazon Cognito Region
        region: 'us-east-1',
        // OPTIONAL - Amazon Cognito User Pool ID
        userPoolId: 'us-east-1_3ogTKvMbe',
        // OPTIONAL - Amazon Cognito Web Client ID
        userPoolWebClientId: '20c7fbmgas82vbnqca7r3g33pd',
    }
});

class AppWithAuth extends React.Component {

    render() {
        return (
            <div>
                <App />
            </div>
        );
    }
}

export default AppWithAuth;
