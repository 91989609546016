import React from 'react';
import {Link} from 'react-router-dom'
import {FaCode} from 'react-icons/fa';

import Button from '@material-ui/core/Button';
import AppBar from '@material-ui/core/AppBar';

export default class Header extends React.Component {

    componentDidMount() {
        var curUrl = window.location.href;
    }

    render() {
        return (
            <header className='header'>
                <h1>
                    <FaCode/> Welcome to JarrodLewis.io
                </h1>
                <nav>
                    <AppBar className='navBar' color='inherit' position="static">
                        <div>
                            <Button style={{color: 'black'}} className='navBarBtn' component={Link} to='/'>Home</Button>
                            <Button style={{color: 'black'}} className='navBarBtn' component={Link} to='/Projects'>Projects</Button>
                            <Button style={{color: 'black'}} className='navBarBtn' component={Link} to='/OpenLayers'>GOES-16 OpenLayers</Button>
                        </div>
                    </AppBar>
                </nav>


            </header>
        )
    }
}
