import React from 'react';

import Button from '@material-ui/core/Button';

import 'typeface-roboto';

function Square(props) {
    return (
        <button
            className="square"
            onClick={props.onClick}
            > {props.value}
        </button>
    );
}

class Board extends React.Component {
    renderSquare(i, rowIdx, colIdx) {
        return (
            <Square
                value={this.props.squares[i]}
                onClick={() => this.props.onClick(i, rowIdx, colIdx)}
                key={i}
            />
        );
    }

    render() {
        let squares = [];
        let i = 0;

        [0, 1, 2].forEach( rowIdx => {
            let row = [];
            [0, 1, 2].forEach( colIdx => {
                row.push(this.renderSquare(i, rowIdx, colIdx))
                i ++;
            })
            squares.push(<div key={rowIdx} className="board-row">{row}</div>);
        })
        return (
            <div>
                {squares}
            </div>
        );
    }
}

export default class Game extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            history: [
                {
                    squares: Array(9).fill(null)
                }
            ],
            stepNumber: 0,
            xIsNext: true
        };
    }

    handleClick(i, rowIdx, colIdx){
        const history = this.state.history.slice(0, this.state.stepNumber + 1);
        const current = history[history.length - 1];
        const squares = current.squares.slice();

        if (calculateWinner(squares) || squares[i]) {
            return;
        }
        squares[i] = this.state.xIsNext
            ? "X"
            : "O";
        this.setState({
            history: history.concat([
                {
                    squares: squares,
                    rowIdx: rowIdx,
                    colIdx: colIdx
                }
            ]),
            stepNumber: history.length,
            xIsNext: !this.state.xIsNext
        });
    }

    jumpTo(step) {
        this.setState({
            stepNumber: step,
            xIsNext: (step % 2) === 0
        });
    }

    render() {
        const history = this.state.history;
        const current = history[this.state.stepNumber];
        const winner = calculateWinner(current.squares);

        const moves = history.map((step, move) => {
            const desc   = move ? 'Go to move #' + move : 'Go to game start';
            const rowIdx = move ? `at Row: ${history[move].rowIdx}` : undefined;
            const colIdx = move ? `, Col: ${history[move].colIdx}` : undefined;

            const style = (move === this.state.stepNumber ? {fontWeight: 'bold'} : {});
            const color = (move === this.state.stepNumber ? 'primary' : 'default');

            return (
                <li key={move}>
                    <Button variant="contained" color={color} style={style} onClick={() => this.jumpTo(move)}>{desc} {rowIdx} {colIdx}</Button>
                </li>
            );
        });

        let status;
        if (winner) {
            status = "Winner: " + winner;
        } else {
            status = "Next player: " + ( this.state.xIsNext ? "X" : "O");
        }

        return (
            <div>
                <h2> Tic Tac Toe</h2>
                <p> A basic implementation of the game that helped me learn React! </p>

                <div className="game">
                    <div className="game-board">
                        <Board squares={current.squares} onClick={(i, rowIdx, colIdx) => this.handleClick(i, rowIdx, colIdx)}/>
                    </div>
                    <div className="game-info">
                        <div>{status}</div>
                        <ol>{moves}</ol>
                    </div>
                </div>
            </div>
        );
    }
}

function calculateWinner(squares) {
    const lines = [
        [
            0, 1, 2
        ],
        [
            3, 4, 5
        ],
        [
            6, 7, 8
        ],
        [
            0, 3, 6
        ],
        [
            1, 4, 7
        ],
        [
            2, 5, 8
        ],
        [
            0, 4, 8
        ],
        [
            2, 4, 6
        ]
    ];
    for (let i = 0; i < lines.length; i++) {
        const [a, b, c] = lines[i];
        if (squares[a] && squares[a] === squares[b] && squares[a] === squares[c]) {
            return squares[a];
        }
    }
    return null;
}
